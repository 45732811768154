import clsx from "clsx";
import { useState } from "react";
import "./style.scss";

type Props = {
  title: string;
  desc: string;
};
const HeaderExpandable = ({ title, desc }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="header-expandable">
      <div
        className={clsx("top", {
          active: open,
        })}
        onClick={() => setOpen((s) => !s)}
      >
        <span>{title}</span>
        <div
          className={clsx("icon-container", {
            active: open,
          })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
          >
            <path
              d="M10 22.288V20.624H29.968L24.4 15.056L25.488 14L32.944 21.456L25.488 28.912L24.4 27.856L29.968 22.288H10Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div
        className={clsx("expandable", {
          open: open,
        })}
      >
        <div>
          <div className="content-wrapper">
            <p>{desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderExpandable;
